import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, TextField,
    Checkbox, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import withMainContainer from '../withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';

const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }

@inject('store')
@observer
class Elements extends Component {
  constructor (props) {
    super(props)
    this.formatGaStore = this.props.store.formatGaStore
    this.elementsStore = this.props.store.elementsStore
    this.esObjectStore = this.props.store.esObjectStore
    this.selection = new Selection()
   
    this.ctxt_de_ref = {}
    this.alternate_ref = {}
    this.prefix_ref = {}
    this.postfix_ref = {}
    this.esObjectStore.langs.map(lang => {
      this.alternate_ref[lang] = React.createRef()
      this.prefix_ref[lang] = React.createRef()
      this.postfix_ref[lang] = React.createRef()
    })
    this.format_ref = React.createRef()
    this.delimiter_ref = React.createRef()
    this.order_ref = React.createRef()

    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
    this.state = {
        hideCriteriaDelDialog: true
    }
  }

  setCriteriaHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.elementsStore.criteriaColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.elementsStore.constCriteriaHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  handleCriteriaItemColumn = (item, index, column) => {
    if (column.fieldName === 'ctxt_de') {
        return (
          item.ctxt_de !== undefined ? item.ctxt_de[this.esObjectStore.defaultLang] : ''
          // item.ctxt_de !== undefined ? Object.entries(item.ctxt_de).map(([key, value]) => (<div key={key}><b>{key}</b>: {value}</div>)) : ''
        )
    } else if (column.fieldName === 'alternate') {
        return (
          item.alternate !== undefined ? item.alternate[this.esObjectStore.defaultLang] : ''
          // item.alternate !== undefined ? Object.entries(item.alternate).map(([key, value]) => (<div key={key}><b>{key}</b>: {value}</div>)) : ''
        )
    } else if (column.fieldName === 'prefix') {
        return (
          item.prefix !== undefined ? item.prefix[this.esObjectStore.defaultLang] : ''
          // item.prefix !== undefined ? Object.entries(item.prefix).map(([key, value]) => (<div key={key}><b>{key}</b>: {value}</div>)) : ''
        )
    } else if (column.fieldName === 'postfix') {
        return (
          item.postfix !== undefined ? item.postfix[this.esObjectStore.defaultLang] : ''
          // item.postfix !== undefined ? Object.entries(item.postfix).map(([key, value]) => (<div key={key}><b>{key}</b>: {value}</div>)) : ''
        )
    } else if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:CRITERIA_EDIT')}
            onClick={() => this.showCriteriaDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showCriteriaDelDialog(index)}
            iconProps={delIcon}
            title={t('common:CRITERIA_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  showCriteriaDialog = (item, index) => {
    if (item === undefined) {
        const sort = this.elementsStore.elements.map(item => Number(item.sort))
      item = {
        cid: '', 
        ctxt_de: {},
        alternate: {},
        format: null,
        postfix: {},
        delimiter: null,
        order: "1",
        prefix: {},
        show_criteria: "false",
        sort: (sort.length > 0 ? Math.max(...sort) + 1 : 1).toString(),
        isEdit: false
      }
    } else {
      item.isEdit = true
      if (item.ctxt_de === undefined) {
        item.ctxt_de = {}
      }
      if (item.alternate === undefined) {
        item.alternate = {}
      }
      if (item.prefix === undefined) {
        item.prefix = {}
      }
      if (item.postfix === undefined) {
        item.postfix = {}
      }
      this.setState({
        criteriaEditIndex: index,
        origElements: JSON.stringify(item)
      })
    }
    this.elementsStore.handleCriteriaDialog(item)
  }

  // criteria
  handleCloseCriteriaDialog = () => {
    this.elementsStore.handleCriteriaDialog()
    const { criteriaEditIndex, origElements } = this.state
    if (criteriaEditIndex !== undefined) {
        this.elementsStore.elements[criteriaEditIndex] = JSON.parse(origElements) 
        this.setState({
            criteriaEditIndex: undefined,
            origElements: ''
        })
    }
  }

  handleCriteriaChange = (key, value) => {
    if (this.elementsStore.criteriaItem !== undefined) {
        this.elementsStore.criteriaItem[key] = value
    }
  }
  
  handleCriteriaEdit = () => {
    let empty = false
    this.esObjectStore.langs.forEach(lang => {
      if (this.elementsStore.criteriaItem.ctxt_de[lang] === '' || this.elementsStore.criteriaItem.ctxt_de[lang] === undefined) {
        empty = true
      }
    })
    if (!this.elementsStore.criteriaItem.cid || empty) {
      alert(this.props.t('common:MSG_SELECT_CRITERIA'))
      return
    }
     // save in criteriaItem the values from refs inputs
     this.esObjectStore.langs.forEach(lang => {
      this.elementsStore.criteriaItem.alternate[lang] = this.alternate_ref[lang].current.value
      this.elementsStore.criteriaItem.prefix[lang] = this.prefix_ref[lang].current.value
      this.elementsStore.criteriaItem.postfix[lang] = this.postfix_ref[lang].current.value
    })
    this.elementsStore.criteriaItem.format = this.format_ref.current.value
    this.elementsStore.criteriaItem.delimiter = this.delimiter_ref.current.value
    this.elementsStore.criteriaItem.order = this.order_ref.current.value

    this.elementsStore.editCriteria()
  }

  showCriteriaDelDialog = index => {
    this.setState({
      hideCriteriaDelDialog: false,
      criteriaDelIndex: index
    })
  }

  handleCloseCriteriaDelDialog = () => {
    this.setState({ hideCriteriaDelDialog: true })
  }

  handleDeleteCriteria = () => {
    this.elementsStore.removeCriteria(this.state.criteriaDelIndex)
    this.setState({
      hideCriteriaDelDialog: true,
      criteriaDelIndex: undefined
    })
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.elementsStore.elements.indexOf(item)
    const items = this.elementsStore.elements.filter(itm => draggedItems.indexOf(itm) === -1)

    items.splice(insertIndex, 0, ...draggedItems)
    this.elementsStore.elements = items
    
    // update sort field
    const sort = this.elementsStore.elements.map(item => Number(item.sort))
    const minsort = sort.length > 0 ? Math.min(...sort) : 1
    for (let index = 0; index < this.elementsStore.elements.length; index++) {
      this.elementsStore.elements[index].sort = (minsort+index).toString()
    }
    this.formatGaStore.editItem.elements = this.elementsStore.elements
  }

  renderFixedDetailsHeader = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip = 
       tooltipHostProps => (
          <TooltipHost {...tooltipHostProps} />
        );
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender({
           ...props,
           onRenderColumnHeaderTooltip,
        })}
      </Sticky>
    );
  }

  render () {
    const { t, store: { formatGaStore: { editItem }, elementsStore: { showCriteriaDialog, criteriaItem, elements },
        esObjectStore : { langs, defaultLang, criterias, mapCriterias } } }  = this.props
    const { hideCriteriaDelDialog, criteriaDelIndex } = this.state
    const stackTokens = {
        childrenGap: 5
    }
    const containerStackTokens = {
        childrenGap: 10,
        padding: 10
    }
    const growingStyles = {
        root: {
            display: 'flex',
            height: '50%'
        }
    }
    const textFieldsStyles = {
        fieldGroup: { width: 400, height: 20, float: 'right',  marginTop: 5 }
    } 
    return (
        <React.Fragment>
            <Stack tokens={containerStackTokens} style={{height: 'inherit'}}>  
                <Stack verticall tokens={stackTokens} >
                    <Stack horizontal tokens={stackTokens}>
                        <span><b>Elements</b></span>
                        <IconButton
                            iconProps={addIcon}
                            title={t('common:CRITERIA_ADD')}
                            onClick={() => this.showCriteriaDialog()}
                        />
                    </Stack>    
                    <div style={{ position: "relative", height: 300 }} id='elements'>    
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                        <DetailsList id='criteriaDetailsList'
                            items={ elements } 
                            setKey='set'
                            columns={this.setCriteriaHeaderColumns()}
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            enterModalSelectionOnTouch
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderItemColumn={this.handleCriteriaItemColumn}
                            dragDropEvents={this.dragDropEvents}
                            onRenderDetailsHeader={this.renderFixedDetailsHeader}
                        />
                        </ScrollablePane>   
                    </div>    
                </Stack>
            </Stack>
        { showCriteriaDialog && <Dialog
            minWidth={1000}
            maxWidth={1000}
            hidden={!showCriteriaDialog}
            onDismiss={this.handleCloseCriteriaDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: criteriaItem !== undefined && criteriaItem.isEdit === true ? t('common:CRITERIA_EDIT') : t('common:CRITERIA_ADD'),
              closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            }}
            modalProps={{
              isBlocking: true, dragOptions: true, 
            }}
          >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: langs.length < 4 ? '20rem' : '30rem'}}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:NODE_FILTER_CAID')}</Label>
              <Dropdown
                placeholder={t('common:NODE_FILTER_CAID')}
                defaultValue={criteriaItem !== undefined ? criteriaItem.cid : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={criterias}
                onChange={(e, { value }) => {
                  this.handleCriteriaChange('cid', value)
                  langs.map(lang => criteriaItem.ctxt_de[lang] = mapCriterias.get(lang).get(value))
                }}
              />
            </Stack>
            <Stack horizontal tokens={stackTokens} id='criteriaDiv'>
              <Stack.Item styles={growingStyles}>  
                <Stack.Item verticalAlign='start' verticalFill  tokens={stackTokens} >  
                <Stack styles={{marginTop: 10}}>    
                  {langs.map(lang => {
                      return (
                      <TextField label={t('common:NODE_FILTER_CAID')+ ' ' + lang} id={'cvp_'+ lang} key={'cvp_'+ lang} 
                        value={criteriaItem !== undefined && criteriaItem.ctxt_de !== undefined? criteriaItem.ctxt_de[lang] || '' : ''} 
                        required type='text' onGetErrorMessage={ value => { if (value === '') return ' ' }} 
                        onChange={(event, newValue) => {
                          if (criteriaItem !== undefined) {
                              criteriaItem.ctxt_de[lang] = newValue
                          }
                        }} styles={textFieldsStyles}
                      />
                      )
                  })}    
                   </Stack>
                   <Stack styles={{root: {marginTop: 10}}}>   
                   {langs.map(lang => {
                      return (
                      <TextField label={t('common:ELEMENT_PREFIX')+ ' ' + lang} ref={this.prefix_ref[lang]} id={'prefix_'+ lang} key={'prefix_'+ lang} 
                        defaultValue={criteriaItem !== undefined && criteriaItem.prefix !== undefined? criteriaItem.prefix[lang] || '' : ''}  
                        styles={textFieldsStyles}
                      />
                      )
                  })}
                  </Stack>
                  <TextField label={t('common:ELEMENT_FORMAT')} ref={this.format_ref} id='format' defaultValue={criteriaItem !== undefined ? criteriaItem.format : null} 
                    styles={{fieldGroup: { width: 400, height: 20, float: 'right', marginTop: 5 }, root: {marginTop: 10}}}/>
                  <TextField label={t('common:ELEMENT_ORDER')} ref={this.order_ref} id='order' defaultValue={criteriaItem !== undefined ? criteriaItem.order : null} 
                    styles={textFieldsStyles}/>
                </Stack.Item>
                <Stack.Item verticalAlign='start' verticalFill  tokens={stackTokens} > 
                <Stack styles={{marginTop: 10}}> 
                  {langs.map(lang => {
                      return (
                      <TextField label={t('common:ELEMENT_ALTERNATE')+ ' ' + lang} ref={this.alternate_ref[lang]} id={'alt_'+ lang} key={'alt_'+ lang} 
                        defaultValue={criteriaItem !== undefined && criteriaItem.alternate !== undefined? criteriaItem.alternate[lang] || '' : ''}  
                        styles={{fieldGroup: { width: 400, height: 20, float: 'right', marginTop: 5 }, root: {paddingLeft: 15}}}
                      />
                      )
                  })}
                  </Stack>
                  <Stack styles={{root: {marginTop: 10}}}> 
                    {langs.map(lang => {
                      return (
                      <TextField label={t('common:ELEMENT_POSTFIX')+ ' ' + lang} ref={this.postfix_ref[lang]} id={'postfix_'+ lang} key={'postfix_'+ lang} 
                        defaultValue={criteriaItem !== undefined && criteriaItem.postfix !== undefined? criteriaItem.postfix[lang] || '' : ''}  
                        styles={{fieldGroup: { width: 400, height: 20, float: 'right', marginTop: 5 }, root: {paddingLeft: 15}}}
                      />
                      )
                    })}
                  </Stack>
                  <TextField label={t('common:ELEMENT_DELIMITER')} ref={this.delimiter_ref} id='delimiter' defaultValue={criteriaItem !== undefined ? criteriaItem.delimiter : null} 
                   styles={{fieldGroup: { width: 400, height: 20, float: 'right', marginTop: 5 }, root: {paddingLeft: 15, marginTop: 10}}}/>
                </Stack.Item>
              </Stack.Item>
             </Stack>
             <Checkbox label={t('common:ELEMENT_SHOW')} checked={criteriaItem !== undefined && criteriaItem.show_criteria === "true" ? true : false} onChange={(event, newValue) => this.handleCriteriaChange('show_criteria', newValue ? "true" : "false")} />   
          </Stack>
            <DialogFooter>
              <PrimaryButton onClick={this.handleCriteriaEdit} text={t('common:BUTTON_SAVE')} />
              <DefaultButton onClick={this.handleCloseCriteriaDialog} text={t('common:BUTTON_CANCEL')} />
            </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideCriteriaDelDialog}
          onDismiss={this.handleCloseCriteriaDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:CRITERIA_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:CRITERIA_DELETE_QESTION') + (elements[criteriaDelIndex] !== undefined ? elements[criteriaDelIndex]?.ctxt_de[defaultLang] : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteCriteria} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseCriteriaDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        </React.Fragment>
    )
  }
}

export { Elements }
export default withTranslation(['common'], { wait: true })(withMainContainer(Elements))