import { observable, action } from 'mobx'
import config from '../../config/config'
const data = require('../../config/data.json')

class FormatGaStore {
    @observable esObjects = []
    @observable formatGaDocs = []
    @observable filteredDocs = []
    @observable editing = false

    @observable selectedESObject = null
    
    @observable filterText

    @observable editItem
    @observable showDialog = false
    
    mapTextsTranslations = new Map()

    @observable columns = []  
    constHeader = [
        { key: 'gatxt', text : 'ARTICLES_GENARTS' },
        { key: 'buttons', text: '' }
    ]

    genarts_display_type = []
    genarts_default = []

    @observable loadingOnSave = false
    
    criteriaDeleted = []

    constructor (authStore, esObjectStore) {
        this.authStore = authStore 
        this.esObjectStore = esObjectStore
        this.constHeader.forEach(({key}) => this.columns.push({
            key,
            fieldName: key,
            isResizable: true,
            width: key === 'buttons' ? '20%' : '80%',
            minWidth: key === 'buttons' ? 50 : 250,
            maxWidth: key === 'buttons' ? 100 : 450,
        }))
        data.genarts_display_type.forEach(type => this.genarts_display_type.push({key: type, text: type}))
        data.genarts_default.forEach(def => this.genarts_default.push({key: def, text: def}))
    }

   /**
   * Get the formats from DB
   */
   populateESObjects = async (reloadShops = false) => {
    this.esObjects =(await this.esObjectStore.populateESObjects('format_ga', reloadShops)).filter(item => this.authStore.hasUserRight(this.esObjectStore.mapShops.get(item.shop_id).shop_name) === true).map(item => ({...item, env_txt: this.esObjectStore.environments[item.env], shop_name: this.esObjectStore.mapShops.get(item.shop_id).shop_name }))
  }

  /**
   * Hide / Show dialog
   */
  handleShowDialog = (item) => {
    this.showDialog = !this.showDialog
    this.editItem = item
  }

  fetchFormatGaTranslations = async (es_object_id) => {
    const result = await fetch(`${config.server_address}/fe/formatTexts/${es_object_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    data.message.forEach(text => {
      this.addTranslationToMap(`${text.format_id}@${text.text_key}`, text.language_iso, text.text)
    });
  }

  addTranslationToMap = (mapKey, lang, text) => {
    if (this.mapTextsTranslations.has(mapKey)) {
      this.mapTextsTranslations.get(mapKey)[lang] = text
    } else {
      this.mapTextsTranslations.set(mapKey, {[lang] : text})
    }
  }

  /**
   * Save formatText in the DB
   */
  editText = async (formatId, key, lang, text) => {
    const result = await fetch(`${config.server_address}/fe/formatTexts/save`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        es_object_id: this.selectedESObject.id,
        format_id: formatId,
        text_key: key,
        language_iso: lang,
        text: text
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
  }

   /**
   * Delete formatText from the DB
   */
   removeText = async (formatId, key) => {
    const result = await fetch(`${config.server_address}/fe/formatTexts/delete`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        es_object_id: this.selectedESObject.id,
        format_id: formatId,
        text_key: key
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
  }
  /**
   * Get the Format ga docs from DB
   */
  populateDocs = async (esObjectId) => {
    this.formatGaDocs = []
    this.filterText = ''

    // fetch format texts
    this.mapTextsTranslations.clear()
    await this.fetchFormatGaTranslations(esObjectId)

    const result = await fetch(`${config.server_address}/fe/formatGa/${esObjectId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success) {
      if (this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang) !== undefined) {
        this.formatGaDocs = data.message.map(item => {
            item.genarts = JSON.parse(item.genarts).map((genart) => {
              return { ...genart, ga_de: this.mapTextsTranslations.get(`${item.id}@ga_de[${genart.id_format}]`) };
            });
            item.elements = JSON.parse(item.elements).map((element) => {
              return {
                ...element,
                // set translations
                ctxt_de : this.mapTextsTranslations.get(`${item.id}@ctxt_de[${element.cid}]`),
                alternate : this.mapTextsTranslations.get(`${item.id}@alternate[${element.cid}]`),
                prefix : this.mapTextsTranslations.get(`${item.id}@prefix[${element.cid}]`),
                postfix : this.mapTextsTranslations.get(`${item.id}@postfix[${element.cid}]`)}
            })
            const format = {...item, gatxt: item.gaid + '-' + this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(item.gaid)}
            return format
        })
        this.filteredDocs = this.formatGaDocs
      }
    }
  }
  /**
   * Remove a format ga from the DB
   */
  removeDoc = async (item) => {
    if (!item) {
      return
    }
    this.loading = true
    // remove from DB 
    const result = await fetch(`${config.server_address}/fe/formatGa/delete/${item.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      // remove deleted node from arrays
      const index = this.formatGaDocs.findIndex(doc => doc.id === item.id)
      index > -1 && this.formatGaDocs.splice(index, 1)
      
      const filteredIndex = this.filteredDocs.findIndex(doc => doc.id === item.id)
      filteredIndex > -1 && this.filteredDocs.splice(filteredIndex, 1)

      this.loading =  false
      
      return true
    }
  }

  saveDocInDB = async(isEdit, formatId) => {
    // save in DB 
    const result = await fetch(`${config.server_address}/fe/formatGa/${isEdit ? 'edit' : 'insert'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: formatId,
        es_object_id: this.selectedESObject.id,
        genarts:  this.editItem.genarts,
        elements: this.editItem.elements,
        updated_by: this.authStore.current_user.DisplayName
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    return result.json()
  }

  deleteCriteriaTexts = async(formatId, cid) => {
    this.mapTextsTranslations.delete(`${formatId}@ctxt_de[${cid}]`)
    await this.removeText(formatId, `ctxt_de[${cid}]`)
    
    this.mapTextsTranslations.delete(`${formatId}@alternate[${cid}]`)
    await this.removeText(formatId, `alternate[${cid}]`)
    
    this.mapTextsTranslations.delete(`${formatId}@prefix[${cid}]`)
    await this.removeText(formatId, `prefix[${cid}]`)
    
    this.mapTextsTranslations.delete(`${formatId}@postfix[${cid}]`)
    await this.removeText(formatId, `postfix[${cid}]`)
  }

  saveDoc = async() => {
    this.loadingOnSave = true
    let formatId = this.editItem.id
    console.log(formatId)
    const genarts = this.editItem.genarts.slice()
    const elements =  this.editItem.elements.slice()
    const isEdit = this.editItem.isEdit
    
    this.editItem.elements.forEach(el => {
      delete el.isEdit
    })
    delete this.editItem.isEdit
    
    // on edit delete the texts from deleted criteria
    if (formatId) {
      for (const crit of this.criteriaDeleted) {
        await this.deleteCriteriaTexts(formatId, crit.cid)
      }
    }
    const res = await this.saveDocInDB(isEdit, formatId)
    if (res.success === true) {
      if (formatId === undefined) {
        formatId = res.message
      }
      // save texts for node in DB and in mapTranslations
      for (const genart of genarts) {
        for (const [lang, text] of Object.entries(genart.ga_de)) {
          this.addTranslationToMap(`${formatId}@ga_de[${genart.id_format}]`, lang, text)
          await this.editText(formatId, `ga_de[${genart.id_format}]`, lang, text)
        }
      }
      for (const el of elements) {
        if (el.ctxt_de) {
          for (const [lang, text] of Object.entries(el.ctxt_de)) {
            this.addTranslationToMap(`${formatId}@ctxt_de[${el.cid}]`, lang, text)
            await this.editText(formatId, `ctxt_de[${el.cid}]`, lang, text)
          }
        }
        if (el.alternate) {
          for (const [lang, text] of Object.entries(el.alternate)) {
            text !== '' && this.addTranslationToMap(`${formatId}@alternate[${el.cid}]`, lang, text)
            text !== '' && await this.editText(formatId, `alternate[${el.cid}]`, lang, text)
          }
        }
        if (el.prefix) {
          for (const [lang, text] of Object.entries(el.prefix)) {
            text !== '' && this.addTranslationToMap(`${formatId}@prefix[${el.cid}]`, lang, text)
            text !== '' && await this.editText(formatId, `prefix[${el.cid}]`, lang, text)
          }
        }
        if (el.postfix) {
          for (const [lang, text] of Object.entries(el.postfix)) {
            text !== '' && this.addTranslationToMap(`${formatId}@postfix[${el.cid}]`, lang, text)
            text !== '' && await this.editText(formatId, `postfix[${el.cid}]`, lang, text)
          }
        }
      }
      await this.populateDocs(this.selectedESObject.id)
    }
    this.loadingOnSave = false
    this.handleShowDialog()
  }
}
export default FormatGaStore