import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, TextField, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter, Dropdown,
  ScrollablePane, ScrollbarVisibility } from '@fluentui/react'
import withMainContainer from './../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown as LookupDropdown} from 'semantic-ui-react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import Elements from '../Extentions/format_ga/Elements';
const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const changeIcon = { iconName: 'ChangeEntitlements' }

@inject('store')
@observer
class FormatGaDocs extends Component {
  constructor (props) {
    super(props)
    this.formatGaStore = this.props.store.formatGaStore
    this.esObjectStore = this.props.store.esObjectStore
    this.elementsStore = this.props.store.elementsStore
    this.selection = new Selection({
      onSelectionChanged: () => {
      }
    })
    this.state = {
      hideDelDialog: true
    }
  }

  handleEdit = () => {
    if (this.formatGaStore.loadingOnSave) {
      return
    }
    let empty = false
    this.esObjectStore.langs.forEach(lang => {
      if (this.formatGaStore.editItem.genarts[0].ga_de[lang] === '' || this.formatGaStore.editItem.genarts[0].ga_de[lang] === undefined) {
        empty = true
      }
    })
    if (!this.formatGaStore.editItem.gaid || empty) {
      alert(this.props.t('common:MSG_SELECT_FILTER_GENART'))
      return
    }
    const find = this.formatGaStore.formatGaDocs.find(format => format.gaid === this.formatGaStore.editItem.gaid && format.id !== this.formatGaStore.editItem.id)
    if (find) {
      alert(this.props.t('common:MSG_FORMAT_SAME_GENART'))
      return
    }
    if (this.formatGaStore.editItem.elements.length < 1) {
      alert(this.props.t('common:MSG_SELECT_CRITERIA'))
      return
    }
    this.formatGaStore.saveDoc()
  }

  showEditDialog = item => {
    this.elementsStore.elements = []
    this.formatGaStore.criteriaDeleted = []
    if (item === undefined) {
      const ids = this.formatGaStore.formatGaDocs.map(f => Number(f.id))
      const nextId = ids.length > 0 ? ((Math.max(...ids)) + 1) : 1
      item = {
        elements: [],
        genarts: [
          {
            ga_de: {},
            display_type: 'web.listparts.list',
            default: 'defined',
            id_format: nextId,
          }
        ]
      }
    } else {
      item.isEdit = true
      if (item.genarts.length > 0 && item.genarts[0].ga_de === undefined) {
        item.genarts[0].ga_de = {}
      }
      item.gatxt = item.gaid + '-' + this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(item.gaid)
      item.elements.slice().sort((e1,e2) => e1.sort - e2.sort).forEach(el => this.elementsStore.elements.push(el))
    }
    console.log(item)
    this.formatGaStore.handleShowDialog(item)
  }

  handleCloseInsertDialog = () => {
    this.formatGaStore.handleShowDialog()
    this.formatGaStore.populateDocs(this.formatGaStore.selectedESObject.id)
  }

  showDelDialog = item => {
    this.setState({
      hideDelDialog: false,
      delItem: item
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.setState({
      hideDelDialog: true,
    })
    this.formatGaStore.removeDoc(this.state.delItem).then((result)=> {
      result && alert(`Format for genart ${this.state.delItem.gaid} successfully deleted !`)
      this.setState({
        delItem: undefined
      })
    })
  }

handleChange = (key, value) => {
    const editItem = this.formatGaStore.editItem
    if (editItem !== undefined) {
      editItem[key] = value
    }
}

setHeaderColumns () {
  const { t } = this.props
  const columnsName = []
  this.formatGaStore.columns.forEach((col, index) => {
    col.name = col.key !== 'buttons' ? t(`common:${this.formatGaStore.constHeader[index].text}`) : ''
    columnsName.push(col)
  })
  return columnsName
}

handleItemColumn = (item, index, column) => {
  if (column.fieldName === 'buttons') {
    const { t } = this.props
    return (
      <Stack horizontal verticalAlign='start' verticalFill='true'>
        <IconButton
          iconProps={editIcon}
          title={t('common:FORMAT_EDIT')}
          onClick={() => this.showEditDialog(item, index)}
        />
        <IconButton
          onClick={() => this.showDelDialog(item)}
          iconProps={delIcon}
          title={t('common:FORMAT_DELETE')}
        />
      </Stack>
    )
  }
  return item[column.fieldName]
}

  render () {
    const { t, store: { formatGaStore: { editItem, showDialog, loading, filteredDocs, genarts_display_type, genarts_default }, esObjectStore : {genarts, mapGenarts, langs } }} = this.props
    const { hideDelDialog, delItem } = this.state
    const stackTokens = {
      childrenGap: 5,
      padding: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
        root: {
            display: 'flex',
            height: '50%'
        }
    }
    const textFieldsStyles = {
      fieldGroup: { width: 450, height: 20, float: 'right',  marginTop: 5 }
    } 
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
            <div id='docs'>
                <IconButton
                  iconProps={addIcon}
                  title={t('common:FORMAT_ADD')}
                  onClick={() => this.showEditDialog()}
                />
                {loading && <Spinner size={SpinnerSize.large} /> }
                <DetailsList ariaLabelForGrid='formatGaDocs'
                  items={filteredDocs} 
                  setKey='set'
                  columns={this.setHeaderColumns()}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.single}
                  selection={this.selection}
                  enterModalSelectionOnTouch={true}
                  selectionPreservedOnEmptyClick={true}
                  constrainMode={ConstrainMode.unconstrained}
                  onRenderItemColumn={this.handleItemColumn}
                />
              </div>
        </Stack>
        {showDialog && <Dialog
          minWidth={1200}
          maxWidth={1200}
          hidden={!showDialog}
          onDismiss={this.handleCloseInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem.id === undefined ? t('common:FORMAT_ADD') : t('common:FORMAT_EDIT'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticall tokens={stackTokens}>
           <Stack.Item styles={growingStyles}> 
              <Label>{t('common:NODE_FILTER_GAID')}</Label>
              <LookupDropdown
                placeholder={t('common:NODE_FILTER_GAID')}
                defaultValue={editItem !== undefined ? editItem.gaid : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={genarts}
                onChange={(e, { value }) => {
                    editItem.gaid = value
                    editItem.gatxt = value + '-' + mapGenarts.get(this.esObjectStore.defaultLang).get(value)
                    editItem.genarts[0].gaid = value
                    langs.map(lang => editItem.genarts[0].ga_de[lang] = mapGenarts.get(lang).get(value))
                }}
              />
           </Stack.Item>
            <Stack.Item styles={growingStyles}>
              <Stack verticalAlign='start' verticalFill  tokens={stackTokens} > 
                {langs.map(lang => {
                      return (
                      <TextField label={t('common:NODE_FILTER_GAID')+ ' ' + lang} id={'gtxt_'+ lang} key={'gtxt_'+ lang} 
                        value={editItem !== undefined && editItem.genarts[0].ga_de !== undefined? editItem.genarts[0].ga_de[lang] || '' : ''} 
                        required type='text' onGetErrorMessage={ value => { if (value === '') return ' ' }} 
                        onChange={(event, newValue) => {
                          if (editItem !== undefined) {
                              editItem.genarts[0].ga_de[lang] = newValue
                          }
                        }} styles={textFieldsStyles}
                      />
                      )
                })} 
              </Stack> 
            </Stack.Item> 
            <Stack.Item styles={growingStyles}>
              <Stack horizontal tokens={stackTokens} styles={{root: {float: 'right'}}}>
                  <Dropdown id='ga_display_type'
                      label={t('common:GA_DISPLAY_TYPE')}
                      defaultSelectedKey={editItem !== undefined ? editItem.genarts[0].display_type : ''}
                      options={genarts_display_type}
                      onChange={(event, { key }) => editItem.genarts[0].display_type = key}
                      styles={{dropdown: { width: 150, height: 20, float: 'right'},
                        caretDownWrapper: { height:20, lineHeight: 'normal' },
                        dropdownItem: 'dropdownItem',
                        dropdownItemSelected: 'dropdownItem',
                        label: { width: 105} 
                      }}
                  />
                  <Dropdown id='ga_default'
                      label={t('common:GA_DEFAULT')}
                      defaultSelectedKey={editItem !== undefined ?  editItem.genarts[0].default : ''}
                      options={genarts_default}
                      onChange={(event, { key }) => editItem.genarts[0].default = key}
                      styles={{dropdown: { width: 150, height: 20, float: 'right' },
                        caretDownWrapper: { height:20, lineHeight: 'normal' },
                        dropdownItem: 'dropdownItem',
                        dropdownItemSelected: 'dropdownItem',
                        label: { width: 100, marginLeft: 10}
                      }}
                  /> 
                </Stack>
            </Stack.Item>
            <Stack.Item styles={{root: {width: '100%', height: '100%'}}}>
              <div id='elements'><Elements/></div>
            </Stack.Item>
          </Stack> 
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {hideDelDialog === false && <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:FORMAT_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:FORMAT_DELETE_QESTION') + (delItem !== undefined ? `(${delItem.gatxt})` : '')
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>} 
      </div>   
    )
  }
}

export { FormatGaDocs }
export default withTranslation(['common'], { wait: true })(withMainContainer(FormatGaDocs))