import { observable } from 'mobx'

class ElementsStore {
    @observable criteriaColumns = []  
    @observable showCriteriaDialog = false
    @observable criteriaItem = undefined
    @observable elements = []

    constCriteriaHeader = [
        { key: 'cid', text : 'CRITERIA_CID', width: '10%', minWidth: 30, maxWidth: 50 },
        { key: 'ctxt_de', text : 'CRITERIA_CVP', width: '15%', minWidth: 200, maxWidth: 300 },
        { key: 'alternate', text : 'ELEMENT_ALTERNATE', width: '10%', minWidth: 100, maxWidth: 100 },
        { key: 'prefix', text : 'ELEMENT_PREFIX', width: '10%', minWidth: 50, maxWidth: 100 },
        { key: 'postfix', text : 'ELEMENT_POSTFIX', width: '10%', minWidth: 50, maxWidth: 100 },
        { key: 'format', text : 'ELEMENT_FORMAT', width: '10%', minWidth: 50, maxWidth: 100 },
        { key: 'delimiter', text : 'ELEMENT_DELIMITER', width: '10%', minWidth: 50, maxWidth: 100 },
        { key: 'show_criteria', text : 'ELEMENT_SHOW', width: '10%', minWidth: 50, maxWidth: 80 },
        { key: 'order', text : 'ELEMENT_ORDER', width: '5%', minWidth: 30, maxWidth: 50 },
        /*{ key: 'sort', text : 'TREE_SORT', width: '5%', minWidth: 30, maxWidth: 50 },*/
        { key: 'buttons', text: '', width: '10%', minWidth: 50, maxWidth: 50 }
    ]

    constructor (authStore, esObjectStore, formatGaStore) {
        this.authStore = authStore 
        this.esObjectStore = esObjectStore
        this.formatGaStore = formatGaStore
        this.constCriteriaHeader.forEach(({key, width, minWidth, maxWidth}) => this.criteriaColumns.push({
            key,
            fieldName: key,
            isResizable: true,
            width,
            minWidth,
            maxWidth
        }))
    }

    /**
    * Hide / Show dialog
    */
    handleCriteriaDialog = (criteria) => {
        this.showCriteriaDialog = !this.showCriteriaDialog
        this.criteriaItem = criteria
    }  

    editCriteria = () => {
        if (!this.criteriaItem.isEdit) {
            this.elements.push(this.criteriaItem)
        }
        this.formatGaStore.editItem.elements = this.elements
        this.handleCriteriaDialog()
    }

    removeCriteria = (criteriaDelIndex) => {
        this.formatGaStore.criteriaDeleted.push(this.elements[criteriaDelIndex])
        this.elements.splice(criteriaDelIndex, 1)
        this.formatGaStore.editItem.elements = this.elements
    }
}

export default ElementsStore
